<div class="flex-1 tw-flex tw-flex-col">
  <di-header />
  <div class="flex-1 tw-flex tw-flex-col">
    <div
      class="flex-1"
      data-testId="page-wrapper"
      [ngClass]="{
        'hidden-vert-menu': (isMenuCollapsed$ | async),
        'page-holder': isSideMenuAvailable$ | async,
        'page-holder-nav-collapse': (isIconMenu$ | async) && (isMenuCollapsed$ | async),
      }"
    >
      <router-outlet />
    </div>
  </div>
</div>
