import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'di-unauthorized',
  templateUrl: './unauthorized.component.html',
  standalone: false,
  host: {
    class: 'tw-block',
  },
})
export class UnauthorizedComponent {
  backToHome() {
    window.location.href = environment.vismaHomeUrl;
  }
}
