<div class="justify-content-between container-white container tw-flex tw-flex-col">
  <vud-alert>
    <b i18n>Welcome to Visma.net Bulk Operations Beta Program!</b>
    <span i18n>
      In the beta program we can't guarantee lightning fast response times or that there will be no bugs.<br />
      Known issues (we are working on fixing these):<br /><br />
      - Slow performance. Based on the number of employees the result could appear after a minute or up to 10 minutes.
      We will continue to process the requests in the background, so you can exit the page and check back later.<br /><br />
      However, the service should generally work as expected. If there are other bugs or issues, please report them to
      us via the feedback form found in the Beta page from the profile menu on the top right.
    </span>
  </vud-alert>
  <div class="justify-content-between align-items-center flex-row tw-flex">
    <label class="switch switch-sm" for="betaProgram">
      <input
        id="betaProgram"
        name="betaProgram"
        data-testid="beta-program-toggle"
        type="checkbox"
        [checked]="betaProgramOn"
        (click)="changeProgram()"
      />
      <span class="togglemark">{{ betaProgramOn ? toggleOnText : toggleOffText }}</span>
    </label>
    <button class="btn btn-primary" [disabled]="!betaProgramOn" (click)="openFeedbackForm()" i18n>
      Send us feedback
    </button>
  </div>
</div>
