<div class="container">
  <h2 i18n>Import from file</h2>
  <div class="grid-margins flex-wrap tw-flex">
    <di-grid-card iconClass="grid-vb-icon" routerLink="/calendar/vacation-balance">
      <di-grid-card-title i18n>Vacation balances</di-grid-card-title>
      <di-grid-card-desc i18n>Use a vacation balance file to do the import in Visma Payroll</di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-vb-icon"
      [isExternalLink]="true"
      (click)="navigateTo('https://wagerun.cj.hrm.stag.visma.net/about')"
    >
      <di-grid-card-title>External link test</di-grid-card-title>
      <di-grid-card-desc>It should open a new tab with about page</di-grid-card-desc>
    </di-grid-card>
  </div>
</div>
